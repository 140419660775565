import React from "react";
import ReactDOM from "react-dom";
import "src/utils/chart";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import ScrollTop from "src/hooks/useScrollTop";
import "nprogress/nprogress.css";
import { Provider } from "react-redux";
import store from "src/store";
import App from "src/App";
import { SidebarProvider } from "src/contexts/SidebarContext";
import * as serviceWorker from "src/serviceWorker";
import { AuthProvider } from "src/contexts/JWTAuthContext";
import TagManager from "react-gtm-module";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import axios from "axios";
import BrowserCommunicator from "./services/browserCommunicator";
import reportWebVitals from "./reportWebVitals";
import { trueProductionFlag, ClientToken } from "./mrkt365config";
import {appInsights}  from "./appInsights";

// Global chunk error handler with retry limiting
(function() {
  // Check how many times we've already tried to reload
  const reloadAttempts = parseInt(sessionStorage.getItem('chunkErrorReloadAttempts') || '0');
  const MAX_RELOAD_ATTEMPTS = 3;

  // Helper to show a user-friendly error message when we hit max retries
  function showErrorMessage() {
    // Create error overlay
    const errorDiv = document.createElement('div');
    errorDiv.style.position = 'fixed';
    errorDiv.style.top = '0';
    errorDiv.style.left = '0';
    errorDiv.style.width = '100%';
    errorDiv.style.height = '100%';
    errorDiv.style.backgroundColor = 'rgba(255, 255, 255, 0.9)';
    errorDiv.style.zIndex = '9999';
    errorDiv.style.display = 'flex';
    errorDiv.style.flexDirection = 'column';
    errorDiv.style.alignItems = 'center';
    errorDiv.style.justifyContent = 'center';
    errorDiv.style.padding = '20px';
    errorDiv.style.textAlign = 'center';

    // Error message content
    errorDiv.innerHTML = `
      <h2 style="color: #e74c3c; margin-bottom: 20px;">We're having trouble loading the application</h2>
      <p style="margin-bottom: 20px; max-width: 600px;">
        We've tried reloading automatically a few times but some resources couldn't be loaded.
        This could be due to network issues or temporary server problems.
      </p>
      <button id="manual-reload" style="
        padding: 10px 20px;
        background-color: #3498db;
        color: white;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        font-size: 16px;
      ">Try Again</button>
      <p style="margin-top: 20px; font-size: 14px; color: #7f8c8d;">
        If the problem persists, please contact support or try again later.
      </p>
    `;

    document.body.appendChild(errorDiv);

    // Add event listener to the reload button
    document.getElementById('manual-reload').addEventListener('click', function() {
      sessionStorage.removeItem('chunkErrorReloadAttempts');
      window.location.reload();
    });

    // Log error to Sentry if available
    if (Sentry && typeof Sentry.captureMessage === 'function') {
      Sentry.captureMessage('Max chunk error reload attempts reached', {
        level: 'error',
        tags: { errorType: 'chunk_load_error', attempts: MAX_RELOAD_ATTEMPTS }
      });
    }

    // Log to App Insights if available
    if (appInsights && appInsights.trackEvent) {
      appInsights.trackEvent({name: 'ChunkLoadErrorMaxRetries'});
    }
  }

  // Handle regular errors (script loading, etc)
  window.addEventListener('error', function(event) {
    // Check if the error is a chunk loading error
    if (
      event.error &&
      (
        event.error.name === 'ChunkLoadError' ||
        event.message && event.message.includes('Loading chunk') ||
        event.message && event.message.includes('ChunkLoadError')
      )
    ) {
      console.log(`Detected chunk loading error (attempt ${reloadAttempts + 1}/${MAX_RELOAD_ATTEMPTS})`);

      // Track error in Sentry if available
      if (Sentry && typeof Sentry.captureEvent === 'function') {
        Sentry.captureEvent({
          message: 'Chunk load error detected',
          level: 'warning',
          tags: { errorType: 'chunk_load_error', attemptNumber: reloadAttempts + 1 },
          extra: { errorMessage: event.message || (event.error && event.error.message) }
        });
      }

      // Log to App Insights if available
      if (appInsights && appInsights.trackEvent) {
        appInsights.trackEvent({
          name: 'ChunkLoadError',
          properties: {
            attemptNumber: reloadAttempts + 1,
            errorMessage: event.message || (event.error && event.error.message)
          }
        });
      }

      if (reloadAttempts < MAX_RELOAD_ATTEMPTS) {
        // Increment the counter and save
        sessionStorage.setItem('chunkErrorReloadAttempts', (reloadAttempts + 1).toString());

        // Reload after a short delay
        setTimeout(function() {
          window.location.reload();
        }, 1000);
      } else {
        console.log('Max reload attempts reached. Showing error message.');
        showErrorMessage();
      }

      // Prevent the error from showing in console
      event.preventDefault();
    }
  }, true); // Capture phase to catch errors early

  // Handle promise rejection errors
  window.addEventListener('unhandledrejection', function(event) {
    if (
      event.reason &&
      (
        (event.reason.name === 'ChunkLoadError') ||
        (typeof event.reason.message === 'string' &&
         event.reason.message.includes('Loading chunk'))
      )
    ) {
      console.log(`Detected chunk loading error in promise (attempt ${reloadAttempts + 1}/${MAX_RELOAD_ATTEMPTS})`);

      if (reloadAttempts < MAX_RELOAD_ATTEMPTS) {
        // Increment the counter and save
        sessionStorage.setItem('chunkErrorReloadAttempts', (reloadAttempts + 1).toString());

        // Reload after a short delay
        setTimeout(function() {
          window.location.reload();
        }, 1000);
      } else {
        console.log('Max reload attempts reached. Showing error message.');
        showErrorMessage();
      }

      // Prevent the error from showing in console
      event.preventDefault();
    }
  });

  // If the page loads successfully, reset the counter
  window.addEventListener('load', function() {
    if (document.readyState === 'complete') {
      // Wait a bit to ensure all chunks are loaded
      setTimeout(function() {
        sessionStorage.removeItem('chunkErrorReloadAttempts');
      }, 2000);
    }
  });
})();

// Global Axios setup for retrying 500 errors and timeouts
(function setupAxiosInterceptors() {
  // Maximum number of retry attempts
  const MAX_RETRY_ATTEMPTS = 3;

  // Exponential backoff delay calculation (in ms): 1000, 2000, 4000, ...
  const getExponentialBackoff = (retryCount) => {
    return Math.pow(2, retryCount) * 1000;
  };

  // Function to check if error is retryable (500 error or timeout)
  const isRetryableError = (error) => {
    // Check for network errors (timeout, no internet, etc.)
    if (!error.response) return true;

    // Check for 500 series errors (server errors)
    return error.response.status >= 500 && error.response.status < 600;
  };

  // Request interceptor to add retry count to request config
  axios.interceptors.request.use(
    config => {
      // Initialize retry count if not present
      config.retryCount = config.retryCount || 0;

      // Set a reasonable timeout if not already specified (15 seconds)
      config.timeout = config.timeout || 15000;

      return config;
    },
    error => Promise.reject(error)
  );

  // Response interceptor to handle retries
  axios.interceptors.response.use(
    response => response, // Return successful responses as-is
    error => {
      // Get the config from the error
      const { config } = error;

      // If no config (i.e., request never sent) or not a retryable error, reject immediately
      if (!config || !isRetryableError(error) || config.skipRetry === true) {
        return Promise.reject(error);
      }

      // Increment the retry count
      config.retryCount = config.retryCount || 0;
      config.retryCount += 1;

      // Check if we've reached max retries
      if (config.retryCount >= MAX_RETRY_ATTEMPTS) {
        // Log to Sentry and AppInsights before rejecting
        if (Sentry && typeof Sentry.captureEvent === 'function') {
          Sentry.captureEvent({
            message: 'API request failed after maximum retries',
            level: 'error',
            tags: {
              errorType: 'api_max_retries',
              status: error.response ? error.response.status : 'network_error',
              url: config.url
            },
            extra: {
              method: config.method,
              url: config.url,
              data: config.data,
              errorMessage: error.message
            }
          });
        }

        if (appInsights && appInsights.trackEvent) {
          appInsights.trackEvent({
            name: 'ApiMaxRetriesReached',
            properties: {
              method: config.method,
              url: config.url,
              status: error.response ? error.response.status : 'network_error',
              errorMessage: error.message
            }
          });
        }

        return Promise.reject(error);
      }

      // Calculate delay using exponential backoff
      const delay = getExponentialBackoff(config.retryCount - 1);

      // Log retry attempt
      console.log(`Retrying request to ${config.url} (attempt ${config.retryCount}/${MAX_RETRY_ATTEMPTS}) after ${delay}ms`);

      // Log to Sentry and AppInsights
      if (config.retryCount === 1) { // Only log the first retry to avoid spam
        if (Sentry && typeof Sentry.captureEvent === 'function') {
          Sentry.captureEvent({
            message: 'Retrying failed API request',
            level: 'warning',
            tags: {
              errorType: 'api_retry',
              status: error.response ? error.response.status : 'network_error',
              url: config.url
            },
            extra: {
              method: config.method,
              url: config.url,
              retryCount: config.retryCount
            }
          });
        }

        if (appInsights && appInsights.trackEvent) {
          appInsights.trackEvent({
            name: 'ApiRetry',
            properties: {
              method: config.method,
              url: config.url,
              status: error.response ? error.response.status : 'network_error'
            }
          });
        }
      }

      // Create a new promise that will retry the request after the delay
      return new Promise(resolve => {
        setTimeout(() => {
          resolve(axios(config));
        }, delay);
      });
    }
  );

  // Set default timeout for all axios requests (15 seconds)
  axios.defaults.timeout = 15000;
})();

// the below header will be injected to every axios req
// TODO: Only add this in req made to MRKT365 APIs
axios.defaults.headers.common["client-token"] = ClientToken;

// var test_env_google_tag_id = "GTM-NDZZTLG";
// var test_env_google_tag_env_ga_auth = "rzhzSizGcZE_85PnEnK9Eg";
// var test_env_google_tag_env_ga_preview = "env-5";

// following object needs to be updated with real production ga_auth and ga_env

var tagManagerArgs = {};
appInsights.loadAppInsights();

// if (env === "development") {
//   tagManagerArgs["gtmId"] = test_env_google_tag_id;
//   tagManagerArgs["gtmAuth"] = test_env_google_tag_env_ga_auth;
//   tagManagerArgs["gtmPreview"] = test_env_google_tag_env_ga_preview;
// }
if (window.location.hostname.includes("mrkt365.com")) {
    tagManagerArgs["gtmId"] = "GTM-W3KBPT8";
    Sentry.init({
        dsn: "https://5fab0d99f2784ea5a3ebf01a5cd2851b@o1341363.ingest.sentry.io/6690302",
        integrations: [new BrowserTracing()],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
        environment: trueProductionFlag
            ? "production"
            : window.location.host.includes("localhost")
            ? "development"
            : "staging",
    });
}

TagManager.initialize(tagManagerArgs);

// eslint-disable-next-line no-new
new BrowserCommunicator();

ReactDOM.render(
    <HelmetProvider>
        <Provider store={store}>
            <SidebarProvider>
                <BrowserRouter>
                    <ScrollTop />
                    <AuthProvider>
                        <App />
                    </AuthProvider>
                </BrowserRouter>
            </SidebarProvider>
        </Provider>
    </HelmetProvider>,
    document.getElementById("root")
);

// eslint-disable-next-line no-console
reportWebVitals(console.log);
serviceWorker.unregister();

function trimText(text, count) {
	return text ? text.substr(0, count) + (text.length > count ? '...' : '') : '';
}

const getCurrentRelativeUrl = () => window.location.pathname + window.location.search;

function getDateObject(date) {
	return new Date(date);
}

function formatDateTo4Y2M2D(date) {
	date = getDateObject(date);
	const year = date.getFullYear();
	const month = String(date.getMonth() + 1).padStart(2, '0');
	const day = String(date.getDate()).padStart(2, '0');
	return `${year}-${month}-${day}`;
}


function formatDateTo2DMonth4Yformat(date) {
	date = getDateObject(date);
	return date.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });
}

function getTimeFromDate(date) {
	return date.getHours() + ':' + date.getMinutes();
}

const removeNullValsFromArray = (data = []) => {
	if (data) {
		return data.filter((val) => !!val);
	} else {
		return [];
	}
};

const titilize = (str) => {
	if (str) {
		return str[0].toUpperCase() + str.slice(1);
	} else {
		return '';
	}
};

export {
	trimText,
	formatDateTo4Y2M2D,
	getCurrentRelativeUrl,
	removeNullValsFromArray,
	titilize,
	formatDateTo2DMonth4Yformat,
};
